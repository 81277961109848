<template>
  <v-container style="max-width: 600px">
    About
  </v-container> 
</template>
<script>
import QIcon from './QIcon.vue';

export default {
  name: 'QAbout',
  components: {
    // eslint-disable-next-line
    QIcon
  }
}
</script>