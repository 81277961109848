// Structured merging two objects includes objects, arrays and primitives
// Equal-name Objects mergings, equal keys recursived mergings
// Equal-name Arrays concatings
// Equal-name Primitives and different items replacings
export const structuredMerge = function(trg, source, clone = true) {
	let target = clone ? structuredClone(trg) : trg;
	if(typeof source == 'object') {
		if(Array.isArray(source)) {
			if(typeof target == 'object') {
				if(Array.isArray(target)) {
					target = target.concat(source);
				} else {
					target = source;
				}
			} else {
				target = source;
			}
		} else {
			if (typeof target == 'object') {
				if(Array.isArray(target)) {
					target = source;
				} else {
					for(let key in source) {
						if(typeof target[key] == 'undefined') {
							target[key] = source[key];
						} else {
							target[key] = structuredMerge(target[key], source[key], false);
						}
					}
				}
			} else {
				target = source;
			}
		} 
	} else {
		target = source;
	}
	return target;
}