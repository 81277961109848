<template>
  <v-dialog v-model="dialog" persistent width="500">
    <v-card 
      :title="
        (type !== null) ? 
          $t('message.shedule.type['+(itemType-1)+']') :
          $t('message.shedule.title')" 
      color="background"
      >
      <v-card-text>
        <v-form v-model="formValid" validate-on="input" action="#" @submit="() => false">
        <v-row justify="center">
          <v-col cols="12" class="my-0 py-0 mt-4">
            <v-select
              v-if="type === null"
              :label="$t('message.shedule.typeSelect')"
              variant="solo"
              density="default"
              hide-details
              v-model="itemType"
              class="mb-2"
              :items="[
                {
                  title: $t('message.shedule.type[0]'),
                  value: shedule.day
                },{
                  title: $t('message.shedule.type[1]'),
                  value: shedule.break
                },{
                  title: $t('message.shedule.type[2]'),
                  value: shedule.book
                }
              ]"
            ></v-select>
            <p class="q-small my-0 py-0">
              {{(itemType !== null) ? $t('message.shedule.typeInfo['+(itemType-1)+']') : ''}}
            </p>
          </v-col>
        </v-row>
        <v-fade-transition>
          <v-row justify="center" v-if="itemType">
            <v-col cols="12" class="mt-4 py-0 mb-0">
              <v-sheet elevation="2" class="w-100 bg-white rounded mb-2">
                <v-table class="w-100">
                  <tr>
                    <td v-for="day in (1,7)" :key="day" class="text-center pt-1">
                      {{$t('message.shedule.week['+(day-1)+']')}}
                    </td>
                  </tr>
                  <tr>
                    <td v-for="day in (1,7)" :key="day" class="text-center">
                      <q-checkbox 
                        light="60"
                        :readonly="!weekMask[day-1]"
                        v-model="weekDays[day-1]"
                      />
                    </td>                  
                  </tr>                
                </v-table>
              </v-sheet>
              <v-fade-transition>
                <p class="q-small" v-if="!weekCheck">
                  {{$t(weekMask.includes(true) ? 'message.shedule.mustWeekDay' : 'message.shedule.zeroWeekDay')}}
                </p>
              </v-fade-transition>
            </v-col>
          </v-row>
        </v-fade-transition>
        <v-fade-transition>
          <v-row justify="center">
            <v-col cols="6" justify="center" class="text-center">
              <p class="q-small text-center">{{$t('message.shedule.from')}}</p>
              <inline-picker ref="pickerStart" v-model="startTime" p-key="1"/>
            </v-col>
            <v-col cols="6" justify="center" class="text-center">
              <p class="q-small text-center">{{$t('message.shedule.to')}}</p>
              <inline-picker ref="pickerEnd" v-model="endTime" p-key="2"/>
            </v-col>          
          </v-row>
        </v-fade-transition> 
        <v-fade-transition>
          <v-sheet v-if="itemType == shedule.day" elevation="2" class="p-0 mt-5 w-100 bg-background_c rounded">
            <v-row justify="center" align="center" class="px-2">
              <v-col cols="2" class="text-center pt-3 pb-1">
                <q-checkbox 
                  light="60"
                  :readonly="false"
                  v-model="booking"
                />
              </v-col>
              <v-col cols="10">
                <p class="q-small">
                  {{$t('message.shedule.dailyBook')}}
                </p>
              </v-col>
            </v-row>
          </v-sheet>
        </v-fade-transition>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="mx-2 my-3 bg-warning"
          elevation="6"
          :disabled="!(formValid && weekCheck && timeCheck && itemType)"
          @click.stop="save"
        >{{$t('message.main.add')}}</v-btn>
        <v-btn
          class="mr-4 ml-0 my-3 bg-primary"
          elevation="6"
          @click.stop="close"
        >{{$t('message.dialogs.close')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
//import QIcon from './QIcon.vue';
import { SHEDULE } from "../Defaults.js";
import QCheckbox from './QCheckbox.vue';
import InlinePicker from "./InlinePicker.vue"
import 'vue-drumroll-datetime-picker/dist/style.css';

export default {
  name: 'ShedulePicker',
  props: {
    modelValue: Boolean,
    start: {
      type: String,
      default: '08:00'
    },
    end: {
      type: String,
      default: '19:00'
    },
    week: {
      type: Number,
      default: 0x7F
    },
    mask: {
      type: Number,
      default: 0x78
    },   
    type: {
      type: Number,
      default: null
    }
  },
  components: {
    QCheckbox,
    InlinePicker
  },  
  data() {
    return {
      date: null,
      dialog: this.modelValue,
      weekDays: this.bitArray(this.week & this.mask), // [true,true,true,true,true,false,false],
      weekMask: this.bitArray(this.mask), // [true,true,true,true,false,false,false],
      startTime: this.start,
      endTime: this.end,
      startTimeX: null,
      endTimeY: null,      
      itemType: this.type,
      formValid: true,
      booking: true,
      shedule: SHEDULE
    }
  },
  computed:{    
    weekCheck() {
      let result = false;
      this.weekDays.forEach((day, idx) => {
        if(day && this.weekMask[idx]) {
          result = true;
        }
      });
      return result;
    },
    timeCheck() {
      return (this.startStamp < this.endStamp);
    },    
    weekBit() {
      let result = 0;
      this.weekDays.forEach((day, idx) => {
        result = result << 1;
        if(day && this.weekMask[idx]) {
          result += 1;
        }        
      });
      return result;
    },
    startStamp() {
      return Math.round(0.001 * Date.parse('1970-01-01T'+(this.startTime ? this.startTime : '00:00')+'Z'));
    },
    endStamp() {
      return Math.round(0.001 * Date.parse('1970-01-01T'+(this.endTime ? this.endTime : '00:00')+'Z'));
    }    
  },
  methods: {
    bitArray(val) {
      let arr = (0x80 + (val & 0x7F)).toString(2).split('');
      arr.forEach((chr, idx) => {
        arr[idx] = (chr == '1') ? true : false;
      });
      arr.shift(1);
      return arr;
    },  
    validationRules() {
      return {
        ok: () => true,
        timeInterval: () => (this.startStamp < this.endStamp) || this.$t("message.validation.timeInterval")
      };
    }, 
    save() {
      this.$refs.pickerStart.save();
      this.$refs.pickerEnd.save();
      this.$nextTick(()=>{
        this.dialog = false;
        this.$emit('save',{
          type: this.itemType,
          week: this.weekBit,
          from: this.startStamp,
          to: this.endStamp,
          booking: this.booking
        });
        this.$emit('update:modelValue',false);
      });
    },
    close() {
      this.dialog = false;
      this.$emit('update:modelValue',false);
    }
  },
  watch: {
    modelValue(value) {
      this.weekDays = this.bitArray(this.week & this.mask);
      this.weekMask = this.bitArray(this.mask);
      this.itemType = this.type;
      this.dialog = value;
    }
  }
}
</script>
<style scoped>
.q-small {
  font-size: 0.9em;
}
</style>