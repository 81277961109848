<template>
  <v-table density="compact" class="d-sm-none d-block">
    <tbody>
      <template v-for="item in pointsList" :key="item.token">
        <template v-if="item.idx">

          <tr v-if="item.first && statement != qu_statement.busy"> <!-- Large Green Button -->
            <td
              colspan="4"
              class="q-td text-center px-0 bg-background_yy pt-5 pb-0 border-b-0"
            >
              <div class="w-100 bg-background my-3" style="height: 12px">
                <v-btn
                  min-width="50%"
                  size="large"
                  color="success"
                  class="mt-n4 rounded-xl"
                  elevation="6"
                  @click.stop="pointAction('complete', item.token)"
                >
                  <q-icon icon="transfer-up" light="100" size="3" />
                  &nbsp; <b>{{ $t("message.main.next") }}</b> &nbsp;
                  <q-icon icon="transfer-up" light="100" size="3" />
                </v-btn>
              </div>
            </td>
          </tr>
          <tr v-if="item.first && statement != qu_statement.busy">  <!-- Client marker under Large Green Button -->
            <td
              colspan="4"
              class="text-end bg-background_yy border-0 pt-2"
              style="height: 1em"
            >
              <span class="text-success text-body-1 font-weight-bold">{{
                $t("message.main.waiting")
              }}</span>
            </td>
          </tr>

          <tr class="item"> <!-- Client main ceil -->
            <td :class="'w-20 q-td text-center px-0 py-1 ' + item.class + ((show_buttons == item.numero) ? ' border-b-0' : '')"> <!-- Numero and Token -->
              <b class="text-primary">{{ item.numero }}</b
              ><br /><span class="text-grey">{{ item.token }}</span>
            </td>

            <template v-if="item.current">   <!-- Yellow ceil under Large Green Button -->
              <td
                :class="'q-td text-right text-primary pl-0 pr-1 ' + item.class + ((show_buttons == item.numero) ? ' border-b-0' : '')"
                nowrap
                colspan="3"
              >
                {{ $t("message.shedule.serviceFrom") }} <b class="text-primary">{{ item.from }}</b>
              </td>
            </template>

            <template v-else-if="item.serviced"> <!-- Beige ceil -->
              <td 
                :class="'q-td text-right text-primary pl-0 pr-1 py-1 ' + item.class + ((show_buttons == item.numero) ? ' border-b-0' : '')"
                nowrap
              >
                {{ $t("message.shedule.inQueueFrom") }}<br />
                {{ $t("message.shedule.waiting") }}
                <span v-if="qu.config.auto_busy"
                  ><br />{{ $t("message.shedule.serviceTime") }}</span
                >
              </td>
              <td
                :class="'q-td text-left pa-0 ' + item.class + ((show_buttons == item.numero) ? ' border-b-0' : '')"
                nowrap
              >
                <b class="text-primary">
                  {{ item.from }}<br />
                  {{ item.wait_time }}
                  <span v-if="qu.config.auto_busy"
                    ><br />{{ item.service_time }}</span
                  >
                </b>
              </td>
            </template>
            <template v-else> <!-- White ceil -->
              <td
                :class="'q-td text-right text-primary pl-0 pr-1 ' + item.class + ((show_buttons == item.numero) ? ' border-b-0' : '')"
                nowrap
              >
                {{ $t("message.shedule.inQueueFrom") }}<br />
                {{ $t("message.shedule.waiting") }}<br />
              </td>
              <td :class="'q-td text-left pa-0 ' + item.class + ((show_buttons == item.numero) ? ' border-b-0' : '')" nowrap>
                <b class="text-primary">{{ item.from }}<br />{{ item.wait_time }}</b>
              </td>
            </template>

            <td v-if="!item.current" :class="'w-10 q-td text-right pl-0 pr-1 ' + item.class + ((show_buttons == item.numero) ? ' border-b-0' : '')">
              <q-icon
                icon="cog"
                light="70"
                @click.stop="pointButton(item.numero)"
                size="1"
              />
            </td>
            </tr>
            <v-fade-transition>
              <tr v-show="(show_buttons == item.numero) && !item.current"> <!-- Buttons ceil -->
                <td                           
                  colspan="4"
                  :class="item.class + ' px-0 text-right'"
                  nowrap
                >                              <!-- Buttons ceil beige -->
                  <template v-for="(act, key) in point_actions" :key="key">
                    <v-btn
                      size="small"
                      v-if="
                        act.statements?.includes(statement) &&
                        (act.id & item.actions) &&
                        (!act.permiss || evalContext(act.permiss))
                      "
                      class="mr-1 px-0"
                      :color="act.color"
                      :disabled="!act.statements?.includes(statement)"
                    >
                      <q-icon
                        v-if="act.method"
                        :icon="act.icon"
                        light="100"
                        @click.stop="pointAction(act.method, item.token)"
                        size="1"
                      />
                      <q-icon v-else :icon="act.icon" light="100" size="1" />
                    </v-btn>
                  </template>
                </td>
              </tr>
            </v-fade-transition>
            <v-fade-transition>
              <tr v-show="false && item.current">
                <td :class="item.class + ' text-right'" nowrap colspan="4"> <!-- Yellow ceil. Client marker -->
                  <span class="text-success text-body-1 font-weight-bold">{{
                    $t("message.main.inProcess")
                  }}</span>
                </td>
              </tr>         
            </v-fade-transition>
          <tr v-if="item.current && statement != qu_statement.busy">  <!-- Large Red Button -->
            <td
              colspan="4"
              class="q-td text-center px-0 bg-background_yy pt-1 pb-5"
            >
              <div class="w-100 bg-background my-3" style="height: 12px">
                <v-btn
                  min-width="50%"
                  size="large"
                  color="warning"
                  class="mt-n4 rounded-xl"
                  elevation="6"
                  @click.stop="pointAction('finish', item.token)"
                >
                  <q-icon icon="transfer-up" light="100" size="3" />
                  &nbsp; <b>{{ $t("message.main.next") }}</b> &nbsp;
                  <q-icon icon="transfer-up" light="100" size="3" />
                </v-btn>
              </div>
            </td>
          </tr>
        </template>
      </template>
    </tbody>
  </v-table>
</template>
<script>
import { DEF, QU_STATEMENT, POINT_ACTIONS } from "../Defaults.js";
import QIcon from "./QIcon.vue";

export default {
  name: "QGridXp",
  components: {
    QIcon,
  },
  props: {
    qu: {
      // qu.config is a queue config
      type: Object,
      default() {
        return structuredClone(DEF.qu);
      },
    },
    config: {
      // This is User config, not queue config
      type: Object,
      default() {
        return structuredClone(DEF.config);
      },
    },
    statement: {
      type: Number,
      default: QU_STATEMENT.free,
    },
    timestamp: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      def: DEF,
      point_actions: POINT_ACTIONS,
      qu_statement: QU_STATEMENT,
      show_buttons: 0     // Numero of buttons row
    };
  },
  computed: {
    pointsList() {
      let list = [];
      let onTop = false;
      if (this.qu.points.length) {
        this.qu.points.forEach((point, id) => {
          let item = structuredClone(point);
          item.actions = 0;
          item.first = false;
          item.current = false;
          item.class = item.to ? "bg-background" : "bg-white";
          item.actions += POINT_ACTIONS.recall.id;
          if (item.to) {
            if (!item.service_time && this.statement == QU_STATEMENT.service) {
              item.current = true;
              item.class = "bg-background_yy border-b-0";
            } else {
              item.actions += POINT_ACTIONS.kill.id;
            }
          } else {
            item.actions += POINT_ACTIONS.delete.id;
            if (id != this.qu.points.length - 1) {
              item.actions += POINT_ACTIONS.skip.id;
            } /*else {
              item.actions += POINT_ACTIONS.noskip.id;
            } */
            if (onTop) {
              if (this.qu?.config?.allow_urgent) {
                item.actions += POINT_ACTIONS.urgent.id;
              }
            } else {
              //item.actions += POINT_ACTIONS.service.id;
              if (this.statement == QU_STATEMENT.free) {
                item.first = true;
                item.class = "bg-background_yy";
              }
              onTop = true;
            }
          }
          let dt = new Date(1000 * item.from);
          let today =
            dt.getDay() == new Date(this.timestamp * 1000).getDay()
              ? true
              : false;
          let tooFar = (this.timestamp - item.from) > DEF.tooFar;
          let wait_time = item.to
            ? item.to - item.from
            : this.timestamp - item.from;
          item.serviced = item.to ? true : false;
          item.from = item.from
            ? dt.toLocaleString("uk", {
                timeStyle: "short",
                timeZone: this.config.timezone,
              }) +
              (today
                ? ""
                : " (" +
                  dt.toLocaleString(
                    "uk",
                    tooFar
                      ? {
                          month: "short",
                          day: "numeric",
                          timeZone: this.config.timezone,
                        }
                      : {
                          weekday: "short",
                          timeZone: this.config.timezone,
                        }
                  ) +
                  ")")
            : "–";
          item.service_time = item.service_time
            ? ((item.service_time > DEF.daySeconds
              ? this.$t("message.main.days",Math.floor(item.service_time / DEF.daySeconds))+' ' : '') +
              new Date(1000 * item.service_time).toLocaleTimeString("uk", {
                timeStyle:
                  item.service_time > DEF.shortTimeLimit ? "short" : "medium",
                timeZone: "UTC",
              }))
            : "–";
          item.wait_time =
            (wait_time > DEF.daySeconds
              ? this.$t("message.main.days",Math.floor(wait_time / DEF.daySeconds))+' ' : '') +
              new Date(1000 * (wait_time % DEF.daySeconds)).toLocaleTimeString("uk", {
                timeStyle:
                  ((item.serviced && (wait_time > DEF.shortTimeLimit)) 
                    || (wait_time > DEF.daySeconds))
                    ? "short"
                    : "medium",
                timeZone: "UTC",
              });
          list.push(item);
        });
      }
      return list;
    },
  },
  methods: {
    pointButton(num) {
      this.show_buttons = (this.show_buttons == num) ? 0 : num;
    },
    evalContext(text) {
      return eval("this." + text);
    },
    pointAction(method, token) {
      this.$emit("action", {
        method: method,
        token: token,
      });
    },
  },
};
</script>
<style scoped>
td.q-empty {
  font-size: 0.2em;
  height: 0.2em !important;
}
</style>
