import { createApp } from 'vue';
import { DEF } from "./Defaults.js";
import App from './App.vue';
import i18n from './i18n';
// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

let token = null;
let reload = false;
let key = null;
let muUrl = new URL(document.location);
if(!(DEF.debug || muUrl.origin.includes('www.'))) {   // www prefix mandatory
  window.location.replace(DEF.homeUrl);
}
muUrl.pathname.split('/').forEach(elem => {
  if(token == 1) {
    token = elem;
  } else if(!token && (elem == 'id')) {
    token = 1;
  } else if(key == 1) {
    key = elem;
  } else if(!key && (elem == 'key')) {
    key = 1;
  }
  if(elem.length) {
    reload = true;
  }
});
if(token && key && 
  (token.length == DEF.tokenLength) &&
  (key == window.localStorage.getItem('key'))) {
    window.localStorage.setItem('user',token);
    window.localStorage.removeItem('key');
}
if(reload) {
  window.location.replace('/');
}

window.addEventListener("storage", () => {
  window.location.replace('/');
});

window.addEventListener('DOMContentLoaded', () => {
  if(!DEF.debug && DEF.app && ('serviceWorker' in navigator)) {
    navigator.serviceWorker.register("/js/sw.js").then(serviceWorker => {
      console.log("Service Worker registered: ", serviceWorker);
    }).catch(error => {
      console.error("Error registering the Service Worker: ", error);
    });
  }
});

const quTheme = {
  dark: false,
  colors: {
    background: 'ffffe0',
    background_a: 'e0e0c0',
    background_b: 'd0d0b0',    
    background_c: 'ffffe8',   
    background_y: 'ffffc0',
    background_yy: 'ffff80',
    surface: 'ffffe8',
    primary: '#404070',
    success: '#00a060',
    warning: '#d00000',
    warning_hi: '#ff0000',
  },
};
const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'quTheme',
    themes: {
      quTheme,
    },
  },  
});
createApp(App).use(vuetify).use(i18n).mount('#app');
//createApp(App).mount('#app')