<template>
  <v-table density="compact" class="d-sm-block d-none">
    <tbody @drop.stop="dropHandler">
      <tr>
        <td class="q-empty bg-background border-0">&nbsp;</td>
        <td class="q-empty bg-background border-0">&nbsp;</td>
        <td class="q-empty bg-background border-0">&nbsp;</td>
        <td class="q-empty bg-background border-0">&nbsp;</td>
        <td class="q-empty bg-background border-0">&nbsp;</td>
      </tr>
      <template v-for="(item, item_idx) in pointsList" :key="item.token">
        <template v-if="item.idx">
          <tr v-if="item.first && statement != qu_statement.busy">
            <td
              colspan="5"
              class="q-td text-center px-0 bg-background_yy pt-5 pb-0 border-b-0"
            >
              <div class="w-100 bg-background my-3" style="height: 12px">
                <v-btn
                  min-width="50%"
                  size="large"
                  color="success"
                  class="mt-n4 rounded-xl"
                  elevation="6"
                  @click.stop="pointAction('complete', item.token)"
                >
                  <q-icon icon="transfer-up" light="100" size="3" />
                  &nbsp; <b>{{ $t("message.main.next") }}</b> &nbsp;
                  <q-icon icon="transfer-up" light="100" size="3" />
                </v-btn>
              </div>
            </td>
          </tr>
          <tr v-if="item.first && statement != qu_statement.busy">
            <td
              colspan="5"
              class="text-end bg-background_yy border-0 pt-2"
              style="height: 1em"
            >
              <span class="text-success text-body-1 font-weight-bold">{{
                $t("message.main.waiting")
              }}</span>
            </td>
          </tr>

          <tr v-if="dNdAllowed && !item.serviced && !item.current">
            <td
              colspan="6"
              :class="'q-empty q-noempty border-0 ' + item.class"
              :data-value="item_idx"
              @dragover.stop="dragOver"
              @dragenter.stop="dragEnter"
              @dragleave.stop="dragLeave"
              @drop.stop="dropHandler"
            >
              &nbsp;
            </td>
          </tr>

          <tr
            class="item"
            :draggable="
              dNdAllowed && !item.serviced && !item.current ? 'true' : 'false'
            "
            :data-value="item_idx"
            @dragstart.stop="dragStart"
          >
            <td :class="'q-td text-center px-0 py-1 ' + item.class">
              <b class="text-primary">{{ item.numero }}</b
              ><br /><span class="text-grey">{{ item.token }}</span>
            </td>

            <template v-if="item.current">
              <td
                :class="'q-td text-right text-primary pl-0 pr-1 ' + item.class"
                colspan="3"
                nowrap
              >
                {{ $t("message.shedule.serviceFrom") }}
                <b class="text-primary">{{ item.from }}</b>
              </td>
            </template>
            <template v-else-if="item.serviced">
              <td
                :class="
                  'q-td text-right text-primary pl-0 pr-1 py-1 ' + item.class
                "
                nowrap
              >
                {{ $t("message.shedule.inQueueFrom") }}<br />
                {{ $t("message.shedule.waiting") }}
                <span v-if="qu.config.auto_busy"
                  ><br />{{ $t("message.shedule.serviceTime") }}</span
                >
              </td>
              <td
                :class="'q-td text-left pa-0 ' + item.class"
                colspan="2"
                nowrap
              >
                <b class="text-primary"
                  >{{ item.from }}<br />
                  {{ item.wait_time }}
                  <span v-if="qu.config.auto_busy"
                    ><br />{{ item.service_time }}</span
                  >
                </b>
              </td>
            </template>
            <template v-else>
              <td
                :class="'q-td text-right text-primary pl-0 pr-1 ' + item.class"
                nowrap
              >
                {{ $t("message.shedule.inQueueFrom") }}<br />
                {{ $t("message.shedule.waiting") }}<br />
              </td>
              <td :class="'q-td text-left pa-0 ' + item.class" nowrap>
                <b class="text-primary"
                  >{{ item.from }}<br />{{ item.wait_time }}</b
                >
              </td>
            </template>
            <template v-if="!item.current">
              <td
                v-if="item.serviced"
                :class="item.class + ' pl-0 pr-2 text-right'"
                nowrap
              >
                <template v-for="(act, key) in point_actions" :key="key">
                  <v-btn
                    size="small"
                    v-if="
                      act.id & item.actions &&
                      (!act.permiss || evalContext(act.permiss))
                    "
                    class="mr-1 px-0"
                    :color="act.color"
                    :disabled="!act.statements?.includes(statement)"
                  >
                    <q-icon
                      v-if="act.method"
                      :icon="act.icon"
                      light="100"
                      @click.stop="pointAction(act.method, item.token)"
                      size="1"
                    />
                    <q-icon v-else :icon="act.icon" light="100" size="1" />
                  </v-btn>
                </template>
              </td>
              <td
                v-else
                :class="item.class + ' pl-0 pr-2 text-right'"
                nowrap
                colspan="2"
              >
                <template v-for="(act, key) in point_actions" :key="key">
                  <v-btn
                    size="small"
                    v-if="
                      act.id & item.actions &&
                      (!act.permiss || evalContext(act.permiss))
                    "
                    class="mr-1 px-0"
                    :color="act.color"
                    :disabled="!act.statements?.includes(statement)"
                  >
                    <q-icon
                      v-if="act.method"
                      :icon="act.icon"
                      light="100"
                      @click.stop="pointAction(act.method, item.token)"
                      size="1"
                    />
                    <q-icon v-else :icon="act.icon" light="100" size="1" />
                  </v-btn>
                </template>
              </td>
            </template>
            <td v-else :class="item.class + ' text-right'" nowrap>
              <span class="text-success text-body-1 font-weight-bold">{{
                $t("message.main.inProcess")
              }}</span>
            </td>
          </tr>

          <tr v-if="item.current && statement != qu_statement.busy">
            <td
              colspan="5"
              class="q-td text-center px-0 bg-background_yy pt-1 pb-5"
            >
              <div class="w-100 bg-background my-3" style="height: 12px">
                <v-btn
                  min-width="50%"
                  size="large"
                  color="warning"
                  class="mt-n4 rounded-xl"
                  elevation="6"
                  @click.stop="pointAction('finish', item.token)"
                >
                  <q-icon icon="transfer-up" light="100" size="3" />
                  &nbsp; <b>{{ $t("message.main.next") }}</b> &nbsp;
                  <q-icon icon="transfer-up" light="100" size="3" />
                </v-btn>
              </div>
            </td>
          </tr>
        </template>
        <tr v-else>
          <td class="q-td text-right px-0 bg-background_a" colspan="5"></td>
        </tr>
      </template>
      <tr v-if="dNdAllowed">
        <td
          colspan="6"
          class="q-empty q-noempty border-0 bg-background"
          :data-value="pointsList.length"
          @dragover.stop="dragOver"
          @dragenter.stop="dragEnter"
          @dragleave.stop="dragLeave"
          @drop.stop="dropHandler"
        >
          &nbsp;
        </td>
      </tr>
    </tbody>
  </v-table>
</template>
<script>
import { DEF, QU_STATEMENT, POINT_ACTIONS } from "../Defaults.js";
import QIcon from "./QIcon.vue";

export default {
  name: "QGridPlus",
  components: {
    QIcon,
  },
  props: {
    qu: {
      // qu.config is a queue config
      type: Object,
      default() {
        return structuredClone(DEF.qu);
      },
    },
    config: {
      // This is User config, not queue config
      type: Object,
      default() {
        return structuredClone(DEF.config);
      },
    },
    statement: {
      type: Number,
      default: QU_STATEMENT.free,
    },
    timestamp: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      def: DEF,
      point_actions: POINT_ACTIONS,
      qu_statement: QU_STATEMENT,
      drag_idx: 0,
    };
  },
  computed: {
    dNdAllowed() {
      return (
        this.qu.config.allow_urgent &&
        this.pointsList.length > 1
      );
    },
    pointsList() {
      let list = [];
      let onTop = false;
      if (this.qu.points.length) {
        this.qu.points.forEach((point, id) => {
          let item = structuredClone(point);
          item.actions = 0;
          item.first = false;
          item.current = false;
          item.actions += POINT_ACTIONS.recall.id;
          item.class = item.to ? "bg-background" : "bg-white";
          if (item.to) {
            if (!item.service_time && this.statement == QU_STATEMENT.service) {
              item.current = true;
              item.class = "bg-background_yy border-b-0";
            } else {
              item.actions += POINT_ACTIONS.kill.id;
            }
          } else {
            item.actions += POINT_ACTIONS.delete.id;
            if (id != this.qu.points.length - 1) {
              item.actions += POINT_ACTIONS.skip.id;
            } else {
              item.actions += POINT_ACTIONS.noskip.id;
            }
            if (onTop) {
              if (this.qu?.config?.allow_urgent) {
                item.actions += POINT_ACTIONS.urgent.id;
              }
            } else {
              //item.actions += POINT_ACTIONS.service.id;
              if (this.statement == QU_STATEMENT.free) {
                item.first = true;
                item.class = "bg-background_yy";
              }
              onTop = true;
            }
          }
          let dt = new Date(1000 * item.from);
          let today =
            dt.getDay() == new Date(this.timestamp * 1000).getDay()
              ? true
              : false;
          let tooFar = (this.timestamp - item.from) > DEF.tooFar;
          let wait_time = item.to
            ? item.to - item.from
            : this.timestamp - item.from;
          item.serviced = item.to ? true : false;
          item.from = item.from
            ? dt.toLocaleString("uk", {
                timeStyle: "short",
                timeZone: this.config.timezone,
              }) +
              (today
                ? ""
                : " (" +
                  dt.toLocaleString(
                    "uk",
                    tooFar
                      ? {
                          month: "short",
                          day: "numeric",
                          timeZone: this.config.timezone,
                        }
                      : {
                          weekday: "short",
                          timeZone: this.config.timezone,
                        }
                  ) +
                  ")")
            : "–";
          item.service_time = item.service_time
            ? (item.service_time > DEF.daySeconds
                ? this.$t(
                    "message.main.days",
                    Math.floor(item.service_time / DEF.daySeconds)
                  ) + " "
                : "") +
              new Date(1000 * item.service_time).toLocaleTimeString("uk", {
                timeStyle:
                  item.service_time > DEF.shortTimeLimit ? "short" : "medium",
                timeZone: "UTC",
              })
            : "–";
          item.wait_time =
            (wait_time > DEF.daySeconds
              ? this.$t(
                  "message.main.days",
                  Math.floor(wait_time / DEF.daySeconds)
                ) + " "
              : "") +
            new Date(1000 * (wait_time % DEF.daySeconds)).toLocaleTimeString(
              "uk",
              {
                timeStyle:
                  (item.serviced && wait_time > DEF.shortTimeLimit) ||
                  wait_time > DEF.daySeconds
                    ? "short"
                    : "medium",
                timeZone: "UTC",
              }
            );
          list.push(item);
        });
      }
      return list;
    },
  },
  methods: {
    dragLeave(event) {
      //console.log('DRAG Leave '+event.target.dataset.value);
      if (!event.target.classList.contains("q-empty")) {
        event.target.classList.add("q-empty");
      }
      event.preventDefault();
    },
    dropHandler(event) {
      let to = event.target.dataset.value;
      //console.log('DROP '+this.drag_idx+' to '+to);
      this.dragLeave(event);
      if (to < this.pointsList.length) {
        this.$emit("action", {
          method: "dragndrop",
          token: this.pointsList[this.drag_idx].token,
          to: this.pointsList[to].token,
        });
      } else {
        this.$emit("action", {
          method: "tail",
          token: this.pointsList[this.drag_idx].token,
        });
      }
    },
    dragEnter(event) {
      //console.log('DRAG '+ this.drag_idx +' Enter to '+event.target.dataset.value);
      if (
        this.drag_idx > event.target.dataset.value ||
        this.drag_idx < event.target.dataset.value - 1
      ) {
        event.target.classList.remove("q-empty");
      }
      event.preventDefault();
    },
    dragOver(event) {
      //event.dataTransfer.dropEffect = 'move';
      event.preventDefault();
    },
    dragStart(event) {
      if (event.target instanceof HTMLTableRowElement) {
        this.drag_idx = event.target.dataset.value;
        //console.log('DRAG '+this.drag_idx);
        //event.dataTransfer.setData('text/x-example', event.target.dataset.value);
        //event.dataTransfer.effectAllowed = 'move';
      } else {
        event.preventDefault();
      }
    },
    evalContext(text) {
      return eval("this." + text);
    },
    pointAction(method, token) {
      this.$emit("action", {
        method: method,
        token: token,
      });
    },
  },
};
</script>
<style scoped>
td.q-noempty {
  background-repeat: repeat;
  background-image: url("../assets/cross.svg");
  background-size: 3%;
}
td.q-empty {
  font-size: 0.3em;
  height: 0.3em !important;
  background-image: none !important;
}
</style>
