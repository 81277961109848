import { DEF } from "./Defaults.js";

let socket = null;
let qToken = null;
let closeTrigger = false;
let messenger = null;

function apiLog(msg, chan = 1) {
  if(DEF.log) {
    console.log('API-'+chan+': '+msg);
  }
}
async function engine(data, url = null) {
  return new Promise((resolve, reject) => {
    url = url ? url : DEF.apiUrl;
    fetch(url, {
      method: "POST",
      mode: "cors",                           // no-cors, *cors, same-origin
      cache: "no-cache",                      // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin",             // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",   // or 'application/x-www-form-urlencoded' 'application/json'
        Accept: "application/json",           // expected data sent back
      },
      redirect: "follow",                     // manual, *follow, error
      referrerPolicy: "no-referrer",          // no-referrer, *client
      body: JSON.stringify(data),             // body data type must match "Content-Type" header
    }).then(response => {
      if(response && response.ok) {
        let response_x = response.clone();
        response.json().then(result => {
          if(typeof result == "object") {
            apiLog('Ok. Fetched: ');
            apiLog(JSON.stringify(result));
            if(!result.error) {
              resolve(result);
            } else {
              apiLog('Server data error', 2);
              resolve(result);
            }
          } else {
            response_x.text().then(result => {
              apiLog('RAW:');
              apiLog(result);
            });            
            reject(new Error('API: Data error (bad object)'));
          }
        }).catch(() => {
          response_x.text().then(result => {
            apiLog('RAW:');
            apiLog(result);
          });           
          reject(new Error('API: Data error (bad json)'));
        });
      } else {
        reject(new Error('API: Response error'));
      }
    }).catch(()=>{
      reject(new Error('API: Data error (bad object)'));
    });
  });
}
export const apiGet = async function(data, url = null) {
  return engine(data, url).catch((err) => {
    apiLog(err.message, 2);
    wsMessage('error');
  }); 
}
// =============== Websockets ================
function wsMessage(message) {
  apiLog('Websocket message: '+message);
  if(messenger) {
    messenger(message);
  }
}

async function wsReConnect() {
  return new Promise((resolve, reject) => {
    if(qToken) {
      apiGet({
        ver: DEF.apiVersion,
        method: "getWsToken",
        data: { token: qToken }
      }, DEF.clientApiUrl).then((result) => {
        if(!result?.error && result?.result?.token) {
          wsConnect(result?.result?.token).then((res) => {
            if(res) {
              apiLog('Websocket re-subscribed');
              resolve(res);
            } else {
              resolve(null);
            }
          }).catch(()=>{
            reject(new Error('API: WS reconnect error'));
          });
        } else {
          reject(new Error('API: WS reconnect server error'));
        }
      }).catch(()=>{
        reject(new Error('API: Get WS socket reconnect error'));
      });
    } else {
      resolve(null);
    }
  });
}
async function wsConnect(token) {
  return new Promise((resolve, reject) => {
    let rejectFlag = false;       // if true - already resolved / rejectd, need message('error');
    if(!socket) {
      socket = new WebSocket(DEF.wsUrl + '?token='+token);
      socket.binaryType = "blob";
      socket.addEventListener('open', () => {
        apiLog('Websocket connected');
        rejectFlag = true;
        resolve(true);        // Ok. connected
      });
      socket.addEventListener('close', (evt) => {
        apiLog('Websocket closed. Code ' + evt.code);
        socket = null;
        if(!closeTrigger)	{     // Unexpected :(
            apiLog('Websocket reconnect');
            setTimeout(() => {
              wsReConnect().then((res)=>{
                rejectFlag = true;
                resolve(res);
              }).catch(()=>{
                if(!rejectFlag) {
                  rejectFlag = true;
                  reject(new Error('API: WS reconnect error'));
                } else {
                  wsMessage('error');
                }
              });
            }, DEF.wsTimeout);
        } else {
          resolve(null);
        }
        closeTrigger = false;
      }); 
      socket.addEventListener('error', () => {
        apiLog('Websocket error');
        socket.close();
        socket = null;
        if(!rejectFlag) {
          rejectFlag = true;
          reject(new Error('API: WS error'));
        }
      }); 
      socket.addEventListener('message', (msg) => {
        wsMessage(msg.data);
      });
    } else {
      apiLog('Websocket already created');
      rejectFlag = true;
      resolve(null);        // No need to connect
    }
  });
}
export const wsSubscribe = async function(token, callback) {  // query token, ws callback
  return new Promise((resolve, reject) => {
    if(!socket) {
      qToken = null;
      messenger = callback;
      closeTrigger = false;
      apiGet({
        ver: DEF.apiVersion,
        method: "getWsToken",
        data: { token: token }
      }, DEF.clientApiUrl).then((result) => {
        if(!result?.error && result?.result?.token) {
          qToken = token;
          wsConnect(result?.result?.token).then((res) => {
            if(res) {              
              apiLog('Websocket subscribed');
            }
            resolve(res);
          }).catch(()=>{
            reject(new Error('API: WS connect error'));
          });
        } else {
          reject(new Error('API: WS connect server error'));
        }
      }).catch(()=>{
        reject(new Error('API: WS get token error'));
      });
    } else {
      resolve(null);
    }
  });
}
export const wsUnsubscribe = function() {
  messenger = null;
  qToken = null;
	if(socket) {
		closeTrigger = true;
		socket.close();
		apiLog('Websocket disconnected');
	}
}
export const wsSend = function(message) {
	if(socket) {
		socket.send(message);
		apiLog('Websocket message sent');
	}
}
export const wsClose = function() {
	socket.close();
}