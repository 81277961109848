<template>
  <div>
    <v-form v-model="setupValid" validate-on="input" action="#" @submit="() => false">
    <template v-for="(item, key) in setup" :key="key" >
      <template v-if="!item.hidden">
        <v-fade-transition>
        <v-row v-if="!item.depend || ((setupScope(item.depend)[setupName(item.depend)]) === true)" class="my-0 py-0" justify="center" >
          <v-col v-if="item.type == -1" cols="12" class="my-0 mt-16 py-0 q-label">
            <p class="q-small text-left text-grey">{{$t('message.setup.'+key)}}</p>
            <v-divider/>
          </v-col>
          <v-col v-if="item.type == -2" cols="12" class="mt-16 mb-2 py-0 q-label">
            <v-divider/>
          </v-col>                  
          <v-col v-if="item.type == 0" cols="12" class="my-0 py-0 q-label">
            <v-switch 
              hide-details
              :readonly="item.premium && !config.premium"
              color="success"
              class="my-0 py-0 q-switch-active" 
              v-model="setupScope(item?.name)[setupName(item?.name)]" 
            >
              <template v-slot:append v-if="item.info">
                <q-icon icon="info" class="mb-n1" size="1" light="50" @click.stop="setupInfo = (setupInfo == key) ? null : key"/>
              </template>                    
              <template v-slot:label>
                <span :class="'q-switch-label text-left ' + ((setupScope(item?.name)[setupName(item?.name)] === true) ? 'text-black' : 'text-grey')">
                  {{(item.premium ? '* ' : '') + $t('message.setup.'+key)}}
                </span>
              </template>
            </v-switch>           
          </v-col>
          <v-col v-if="item.type == 1" cols="12" class="my-0 py-0">
            <v-text-field
              type="input"
              :readonly="item.premium && !config.premium"
              variant="solo"
              :bg-color="(item.premium && !config.premium) ? 'background_a' : undefined"
              class="mt-2 mb-n2 py-0"  
              v-model="setupScope(item?.name)[setupName(item?.name)]"
              :label="(item.premium ? '* ' : '') + $t('message.setup.'+key)"
              :rules="[validationRules(item.min, item.max).length]"
              :placeholder="item.placeholder"
            >
            <template v-slot:append v-if="item.info">
              <q-icon icon="info" class="mb-n1" size="1" light="50" @click.stop="setupInfo = (setupInfo == key) ? null : key"/>
            </template>
            </v-text-field>    
          </v-col>
          <v-col v-if="item.type == 2" cols="12" class="my-0 py-0">
            <v-text-field
              type="number"
              :readonly="item.premium && !config.premium"
              variant="solo"
              :bg-color="(item.premium && !config.premium) ? 'background_a' : undefined"
              class="mt-2 mb-n2 py-0" 
              v-model="setupScope(item?.name)[setupName(item?.name)]"
              :label="(item.premium ? '* ' : '') + $t('message.setup.'+key)"
              :rules="[validationRules(item.min, item.max).number]"
            >
            <template v-slot:append v-if="item.info">
              <q-icon icon="info" class="mb-n1" size="1" light="50" @click.stop="setupInfo = (setupInfo == key) ? null : key"/>
            </template>                    
            </v-text-field>    
          </v-col> 
          <v-col v-if="item.type == 3" cols="12" class="my-0 py-0">
            <v-select
              :label="(item.premium ? '* ' : '') + $t('message.setup.'+key)"
              variant="solo"
              density="default"
              hide-details
              v-model="setupScope(item?.name)[setupName(item?.name)]"
              :bg-color="(item.premium && !config.premium) ? 'background_a' : undefined"
              class="mt-2 mb-3 py-0" 
              :readonly="item.premium && !config.premium"
              :items="createSelectOptions(item?.options, key)"
              :rules="[validationRules()[item.required ? 'required' : 'ok']]"
            >
            <template v-slot:append v-if="item.info">
              <q-icon icon="info" class="mb-n1" size="1" light="50" @click.stop="setupInfo = (setupInfo == key) ? null : key"/>
            </template>                      
            </v-select>    
          </v-col>    
          <v-col v-if="item.info" cols="12" class="my-0 py-0">
            <v-fade-transition>
              <p class="mt-n2 mb-2 q-small text-left text-grey" v-if="setupInfo == key" @click.stop="setupInfo = null">
                {{$t('message.setup.'+key+'_info')}}
              </p>
            </v-fade-transition>
          </v-col>                                            
        </v-row>    
        </v-fade-transition>
      </template>
    </template>
    </v-form>
    <v-row v-if="!config.premium" class="my-0 py-0" justify="center">
      <v-col cols="12" class="my-0 py-0">
        <p class="q-small text-left">* {{$t('message.setup.premiumInfo')}}</p>
      </v-col>
    </v-row>
    <v-row class="my-0 py-0" justify="center">
      <v-col cols="12">
        <p v-if="!setupValid">
          {{$t('message.setup.resolve_errors')}}
        </p>
      </v-col>
    </v-row>     
    <v-row class="my-0 py-0" justify="center">
      <v-col cols="6">
        <v-btn :disabled="!setupValid" color="success" class="w-100" elevation="6" @click.stop="saveSetup">
          {{$t('message.main.saveChanges')}}
        </v-btn>
      </v-col>
    </v-row> 
    <v-row class="my-0 py-0" justify="center">
      <v-col cols="12">
        <p>
          {{$t('message.setup.cleanup_alert')}}
        </p>        
      </v-col>
    </v-row>          
  </div>
</template>
<script>
import { DEF, SETUP } from "../Defaults.js";
import QIcon from './QIcon.vue';

export default {
  name: 'QSetup',
  components: {
    QIcon
  },
  props: {
    qu: {
      type: Object,
      default() {
        return structuredClone(DEF.qu);
      }
    },
    config: {
      type: Object,
      default() {
        return structuredClone(DEF.config);
      }
    }
  },
  data() {
    return {
      def: DEF,
      setup: SETUP,
      setupInfo: null,
      setupValid: true,
    };
  },
  methods: {
    setupScope(item) {
      let scope = item.split('.');
      let links = [];
      links.push(this.qu);
      scope.forEach((name, idx) => {
        if(idx < (scope.length-1)) {
          links.push((links[idx])[name]);
        }
      });
      return links.pop();
    },
    setupName(item) {
      let scope = item.split('.');
      return scope[scope.length-1];
    },
    createSelectOptions(qty, key) {
      let options = [];
      for(let id=0; id<qty; id++) {
        options.push({
          value: id+1,
          title: this.$t('message.setup.'+key+'_options['+id+']')
        });
      }
      return options;
    },
    validationRules(min = 0, max = null) {
      return {
        ok: (value) => value ? true : true,
        required: (value) => !!value || this.$t("message.validation.required"),        
        number: (value) =>
          (((min === null) || (parseFloat(value) >= min)) && 
            ((max === null) || (parseFloat(value) <= max))) || 
              (this.$t("message.validation.numberValue")+' '+
                ((min === null) ? '' : min) + ' ... ' + ((max === null) ? '' : max)),
        length: (value) =>
          (value && ((min === null) || (value.length >= min)) && 
            ((max === null) || (value.length <= max))) || 
            (this.$t("message.validation.lengthValue")+' '+
              ((min === null) ? '' : min) + ' ... ' + ((max === null) ? '' : max))
      };
    },
    saveSetup() {
      this.$emit('save');
    }    
  }
}
</script>
<style scoped>
.q-switch-label, .q-small {
  font-size: 0.9em;
}
</style>