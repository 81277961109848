<template>
  <div>
    <template v-for="(item, key) in sheduleArray(category)" :key="key">
      <v-fade-transition>
        <div>
          <v-row class="my-0 py-0" justify="center" > 
            <v-col cols="12" class="my-0 py-0">
              <v-sheet elevation="2" class="w-100 bg-white rounded mb-2">
                <v-table class="w-100">
                  <tr>
                    <td v-for="day in (1,7)" :key="day" class="text-center pt-1">
                      {{$t('message.shedule.week['+(day-1)+']')}}
                    </td>
                  </tr>
                  <tr>
                    <td v-for="day in (1,7)" :key="day" class="text-center">
                      <q-icon 
                        light="60" 
                        :icon="(item?.filter?.week & (0x80 >> day)) ? 'checkbox-marked' : 'checkbox-blank-outline'"
                      />           
                    </td>
                  </tr>
                </v-table>
              </v-sheet>
            </v-col>
          </v-row>    
          <v-row class="my-0 py-0" justify="center" > 
            <v-col cols="9" class="my-0 py-0 text-left">                             
              <h1 :class="item.booking ? 'text-cyan' : 'text-grey'">
                <span v-if="false && item.booking">*</span>
                {{item.from_t}} – {{item.to_t}}
              </h1>
            </v-col>
            <v-col cols="3" class="my-0 mt-1 py-0 text-right">                             
              <v-btn 
                :disabled="item.fixed"
                class="ma-0 bg-warning"
                elevation="6"
                :title="$t('message.shedule.killWorkday')"
              >
                <q-icon 
                  light="100" 
                  icon="trash"
                  @click.stop="killShedule(item.id)"
                /> 
              </v-btn>                          
            </v-col>
          </v-row>
          <v-row class="my-0 py-0" justify="center" > 
            <v-col cols="12" class="my-0 mb-12 py-0">
              <v-divider /> 
            </v-col>
          </v-row>
        </div>
      </v-fade-transition>
    </template>
    <v-row class="my-0 py-0" justify="center" > 
      <v-col cols="12" class="my-0 py-0">
        <p class="q-small" v-if="category == shedule.day">{{$t('message.shedule.addAlert')}}</p>
        <v-btn 
          :disabled="workdaysFull && (category == shedule.day)"
          class="ma-3 bg-primary"
          elevation="6"
          @click.stop="sheduleAdd"
        >
        {{$t('message.shedule.titleAdd['+(category-1)+']')}}
        </v-btn>                        
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { DEF, SHEDULE } from "../Defaults.js";
import QIcon from './QIcon.vue';

export default {
  name: 'QShedule',
  components: {
    QIcon
  },
  props: {
    qu: {
      type: Object,
      default() {
        return structuredClone(DEF.qu);
      }
    },    
    category: {
      type: Number,
      default: SHEDULE.day
    }
  },
  data() {
    return {
      def: DEF,
      shedule: SHEDULE
    };
  },
  computed: {
    workdaysFull() {
      return (this.workdaysExists == 0x7F) ? true : false;
    },
    workdaysExists() {
      let result = 0;
      this.qu.shedules?.workdays?.forEach(day => {
        result |= day?.filter?.week;
      });
      return result;
    },    
    bookingList() {
      let list = structuredClone(this.qu.shedules.bookings);
      this.qu.shedules.workdays.forEach(day => {
        if(day.booking) {
          let bk = structuredClone(day);
          bk.fixed = true;
          list.unshift(bk);
        }
      });
      return list;
    }
  },
  methods: {
    sheduleArray(type) {
      return (type == 1 ? this.qu.shedules.workdays : (
        (type == 2) ? this.qu.shedules.breaks : this.bookingList));
    },
    sheduleAdd() {
      this.$emit('add');
    },
    killShedule(id) {
      this.$emit('kill', id);
    }
  }
}
</script>