<template>
  <q-icon 
    :light="light" 
    @click.stop="check" 
    :icon="readonly ? (modelValue ? 'checkbox-outline' : 'checkbox-blank-outline') :
      (modelValue ? 'checkbox-marked' : 'checkbox-blank')"
  />
</template>
<script>
import QIcon from './QIcon.vue';
export default {
  name: 'QCheckbox',
  components: {
    QIcon
  },   
  props: {
    modelValue: Boolean,
    readonly: Boolean,
    light: String
  },
  methods:{
    check() {
      if(!this.readonly) {
        this.$emit('update:modelValue',!this.modelValue);
      }
    }
  }
}
</script>
<style scoped>
.q-icon-0 {
  height: 1em;
}
.q-icon-1 {
  height: 1.5em;
}
.q-icon-2 {
  height: 2em;
}
.q-icon-3 {
  height: 2.5em;
}
.q-icon-4 {
  height: 3em;
}
</style>